<template>
  <v-data-table
    :headers="headers"
    :items="reports"
    :search="search"
    :server-items-length="total"
    :options.sync="serverOptions"
    :sort-by.sync="sortBy"
    :sort-desc.sync="sortDesc"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <!-- <v-toolbar-title> -->
        <v-text-field
          v-model="search"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
        <!-- </v-toolbar-title> -->
        <!-- <v-divider class="mx-4" inset vertical></v-divider> -->
        <v-spacer></v-spacer>

        <v-dialog
          v-model="dialogDelete"
          max-width="600px"
        >
          <v-card>
            <v-card-title class="text-h5 mb-5">
              Are you sure you want to delete this item?
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="error"
                outlined
                @click="closeDelete"
              >
                Cancel
              </v-btn>
              <v-btn
                color="primary"
                @click="deleteItemConfirm"
              >
                OK
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.index="{ index }">
      {{ ((serverOptions.page-1)*serverOptions.itemsPerPage) + index +1 }}
    </template>
    <template v-slot:item.emp_date="{ item }">
      <span v-if="item.mem_date && item.mem_time">
        {{ item.mem_date }} {{ item.emp_time }}
      </span>
      <span v-if="!item.mem_date || !item.mem_time">
        {{ item.emp_date }} {{ item.mem_time }}
      </span>
    </template>
    <template v-slot:item.description="{ item }">
      <div v-if="item.order && item.order.order_inv">
        <span v-if="item.credit_product_amt > 1">สั่งซื้อสินค้า</span>
      </div>
      <div v-if="item.member_package && item.member_package.mem_package_no">
        <span v-if="item.credit_product_amt > 1">สั่งซื้อแพ็กเกจ</span>
        <span v-if="item.credit_product_amt <= 1">สั่งซื้อสินค้า</span>
      </div>
      <div v-if="item.claim_id">
        <span v-if="item.credit_product_amt > 1">คืนเครดิตสินค้า</span>
      </div>
    </template>
    <template v-slot:item.credit_product_amt="{ item }">
      <span v-if="item.credit_product_amt > 0">{{item.credit_product_amt}}</span>
    </template>
    <template v-slot:item.credit_product_amount="{ item }">
      <span v-if="item.credit_product_amt < 0">{{item.credit_product_amt}}</span>
    </template>
    <template v-slot:item.order_id="{ item }">
      <span v-if="item.order">
        {{ item.order_inv }}
      </span>
      <span v-if="item.member_package">
        {{ item.member_package.mem_package_no }}
      </span>
      <span v-if="item.claim_id ">
        {{ item.claim_id }}
      </span>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        {{ icons.mdiPencil }}
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
      >
        {{ icons.mdiDelete }}
      </v-icon>
    </template>
    <template v-slot:no-data>
      <!-- <v-btn
        color="primary"
        @click="initialize"
      >
        Reset
      </v-btn> -->
    </template>
  </v-data-table>
</template>

<script>
import { mdiPencil, mdiDelete } from '@mdi/js'
// import config from '../../../config'
import instance from '../../../axios.service'
import instance_report from '../../../services/report'

export default {
  setup() {
    return {
      icons: {
        mdiPencil,
        mdiDelete,
      },
    }
  },
  data: () => ({
    serverOptions : {
      page: 1,
      itemsPerPage: 10,
    },
    total : 0,
    reports: [],
    dialog: false,
    dialogDelete: false,
    search: '',
    sortBy: '',
    sortDesc: '',
    headers: [
      {
        text: 'ลำดับ',
        value: 'index',
        sortable: false
      },
      { text: 'วัน-เวลา', value: 'emp_date' },
      { text: 'เลขอ้างอิง', value: 'order_id' },
      { text: 'ประเภทแพ็กเกจ', value: 'product_type.product_type_name' , sortable: false},
      { text: 'รายละเอียด', value: 'description' },
      { text: 'คืนเครดิต', value: 'credit_product_amt' },
      { text: 'ตัดเครดิต', value: 'credit_product_amount' },
      { text: 'คงเหลือ', value: 'remain' },
      { text: 'ชื่อร้าน', value: 'member.member_shop_name', sortable: false },
      // { text: 'แก้ไขข้อมูล', value: 'actions', sortable: false },
    ],
    desserts: [],
    editedIndex: -1,
    editedItem: {
      employee_type_name: '',
      employee_type_comment : ''
    },
    defaultItem: {
      employee_type_name: '',
      employee_type_comment : ''
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'เพิ่ม กลุ่มพนักงาน' : 'แก้ไข กลุ่มพนักงาน'
    },
  },

  watch: {
    serverOptions : {
      handler(){
        this.getReport()
      },
    },
    search : {
      handler(){
        this.getReport()
      },
    },
    // dialog(val) {
    //   val || this.close()
    // },
    // dialogDelete(val) {
    //   val || this.closeDelete()
    // },
  },

  created() {
    // this.initialize()
  },
  mounted() {
    // this.getUser()
    this.getReport()
  },

  methods: {

    editItem(item) {
      this.editedIndex = this.reports.indexOf(item)
      this.editedItem = { ...item }
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.reports.indexOf(item)
      this.editedItem = { ...item }
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      instance_report.employeeTypeDestroy(this.editedItem).then(res => {

        this.reports.splice(this.editedIndex, 1)
      }).catch(err => {

      })
      this.closeDelete()
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem }
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem }
        this.editedIndex = -1
      })
    },

    save() {
      console.log(this.editedIndex,444)
      if (this.editedIndex > -1) {
        instance_report.employeeTypeUpdate(this.editedItem)
          .then(res => {
            Object.assign(this.employees_types[this.editedIndex], this.editedItem)
          }).catch(err => {

        });
      } else {
        instance_report.employeeTypeStore(this.editedItem)
          .then(res => {
            this.employees_types.push(this.editedItem)
          }).catch(err => {

        });
      }
      this.close()
    },
    getReport() {
      this.$store.state.isLoading = true
      var data = {
        search: this.search,
        size : this.serverOptions.itemsPerPage,
        page : this.serverOptions.page,
        date : '',
        status : '',
        sortBy : this.sortBy ?? "",
        sortDesc : this.sortDesc ?? ''
      }
      instance_report.getReportCredit(data).then(res => {
        this.reports = res.data.data.data;
        this.total = res.data.data.total
        this.serverOptions.itemsPerPage = this.serverOptions.itemsPerPage == -1 ? -1 : res.data.data.per_page
        this.serverOptions.page = res.data.data.current_page
        this.$store.state.isLoading = false
      }).catch(err => {
        this.$store.state.isLoading = false

      });
    },
  },
}
</script>

<style scoped>
hr {
  background-color: #e5e5e5;
  height: 1px;
  border: none;
}
</style>
