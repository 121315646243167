var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.reports,"search":_vm.search,"server-items-length":_vm.total,"options":_vm.serverOptions,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"update:options":function($event){_vm.serverOptions=$event},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-text-field',{attrs:{"label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 mb-5"},[_vm._v(" Are you sure you want to delete this item? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","outlined":""},on:{"click":_vm.closeDelete}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.deleteItemConfirm}},[_vm._v(" OK ")]),_c('v-spacer')],1)],1)],1)],1)]},proxy:true},{key:"item.index",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s(((_vm.serverOptions.page-1)*_vm.serverOptions.itemsPerPage) + index +1)+" ")]}},{key:"item.emp_date",fn:function(ref){
var item = ref.item;
return [(item.mem_date && item.mem_time)?_c('span',[_vm._v(" "+_vm._s(item.mem_date)+" "+_vm._s(item.emp_time)+" ")]):_vm._e(),(!item.mem_date || !item.mem_time)?_c('span',[_vm._v(" "+_vm._s(item.emp_date)+" "+_vm._s(item.mem_time)+" ")]):_vm._e()]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [(item.order && item.order.order_inv)?_c('div',[(item.credit_product_amt > 1)?_c('span',[_vm._v("สั่งซื้อสินค้า")]):_vm._e()]):_vm._e(),(item.member_package && item.member_package.mem_package_no)?_c('div',[(item.credit_product_amt > 1)?_c('span',[_vm._v("สั่งซื้อแพ็กเกจ")]):_vm._e(),(item.credit_product_amt <= 1)?_c('span',[_vm._v("สั่งซื้อสินค้า")]):_vm._e()]):_vm._e(),(item.claim_id)?_c('div',[(item.credit_product_amt > 1)?_c('span',[_vm._v("คืนเครดิตสินค้า")]):_vm._e()]):_vm._e()]}},{key:"item.credit_product_amt",fn:function(ref){
var item = ref.item;
return [(item.credit_product_amt > 0)?_c('span',[_vm._v(_vm._s(item.credit_product_amt))]):_vm._e()]}},{key:"item.credit_product_amount",fn:function(ref){
var item = ref.item;
return [(item.credit_product_amt < 0)?_c('span',[_vm._v(_vm._s(item.credit_product_amt))]):_vm._e()]}},{key:"item.order_id",fn:function(ref){
var item = ref.item;
return [(item.order)?_c('span',[_vm._v(" "+_vm._s(item.order_inv)+" ")]):_vm._e(),(item.member_package)?_c('span',[_vm._v(" "+_vm._s(item.member_package.mem_package_no)+" ")]):_vm._e(),(item.claim_id )?_c('span',[_vm._v(" "+_vm._s(item.claim_id)+" ")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencil)+" ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiDelete)+" ")])]}},{key:"no-data",fn:function(){return undefined},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }